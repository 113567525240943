@import '~antd/dist/antd.css';
/*@import '~antd/dist/antd.dark.css';*/

:root {
  /*
  --menu-bg-color: #efebe9;
  --menu-triat: #d7ccc8;
  --menu-triat-lateral: #bcaaa4;
  --menu-superior-bg: #bcaaa4;
*/
/*
  --menu-superior-bg: #142850;
  --menu-bg-color: #dae1e7;
  --menu-triat: #27496d;
  --menu-triat-lateral: #00909e;
*/


  /*--menu-superior-bg: #192441;*/
  --menu-superior-bg: #fdd998;
  --menu-bg-color: #ffecc7;
  --menu-triat: #f5b971;
  --menu-triat-lateral: #f4a548; /*#fdd998;*/

}

/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

*/

/* components/MenuSuperior.js */
/*
a.ant-btn.link-menu-superior.ant-btn-link {
  font-size: 20px;
  font-weight: 500;
  color: white;
}
a.ant-btn.link-menu-superior.ant-btn-link:hover{
    color: #1890ff;
}

*/
.menu_superior{
  background-color: var(--menu-superior-bg);
  padding: 0;
}


/* components/MenuLateral.js */

/*
.ant-layout-sider-zero-width-trigger {
    background: var(--menu-bg-color)!important;
    color: rgba(0,0,0,0.65)!important;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: var(--menu-bg-color)!important;
  color: white!important;
}

.MenuLateral {
  background-color: var(--menu-bg-color)!important;
}
.MenuLateral.ant-menu-submenu > .ant-menu {
  background-color: var(--menu-bg-color)!important;
}


.MenuLateral.ant-menu-item-selected{
  background-color: var(--menu-triat)!important;
  color: white!important;
}

.MenuLateral.ant-submenu-selected, .MenuLateral.ant-menu-submenu-selected{
  color: rgba(0,0,0,0.65)!important;
}

.MenuLateral.ant-menu-item::after{
  border-right: 3px solid var(--menu-triat-lateral)!important;
}

.MenuLateral.ant-menu-item:hover{
  color: black!important;
}

.MenuLateral.ant-menu-item:hover, .MenuLateral.ant-menu-item-active, .MenuLateral.ant-menu:not(.ant-menu-inline) .MenuLateral.ant-menu-submenu-open, .MenuLateral.ant-menu-submenu-active, .MenuLateral.ant-menu-submenu-title:hover {
      color: black!important;
}

.ant-menu-item:hover, .ant-menu-submenu-arrow:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: black!important;
}


*/

/*boto de tornar dels formularis */
  .BotoBack{
    font-size: 1.5rem;
    /*color: rgba(0, 0, 0, 0.65)!important;*/
    color: #d9d9d9;
    cursor: pointer;
  }

  .BotoBack:hover {
    color:#1890ff;
  }


/*components/Users/Taula*/
.BotoTaula{
  border: none!important;
  color: rgba(0, 0, 0, 0.65)!important;
}

.BotoTaula:hover {
  color:#1890ff!important;
}

/* components/DocumentUpload */
/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}


/* qualsevol taula striped  usat a /pages/botiga/Producte.js*/

/*
.taula-striped-rows {
  background-color: #eeeeee;

}
*/
/*
.taula-striped-rows tr:nth-child(1n) td {
    background-color: #e0f7fa;
    height: 0!important;
}
.taula-striped-rows tr:nth-child(2n) td {
    background-color: #b2ebf2;
    height: 0!important;
}
.taula-striped-rows thead {
    background-color: #80deea;
}
*/

/* Usat a components/formularis/UnitatFamiliar.js */
.bg-error-color, .bg-error-color:hover{
  background-color: #ef9a9a!important;
  border-color: #ef9a9a!important;
}

/* generic */
.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success:hover {
  background-color: #28D945;
  border-color: #28D945;
}

/* usat a components/Formularis/perfils/pares/Documents.js */
.doc-eliminar:hover{
  background-color: #ef9a9a;
}


/* Usat a Taula Notificacions*/
.txt-negreta {
  font-weight: bolder;
}
.txt-normal{
  font-weight: normal;
}
